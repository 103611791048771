/**
 * 全局的业务属性常量在这里定义，页面通过 $const 引用
 */

export const STATUS = [
  { value: 1, label: "正常" },
  { value: 0, label: "禁用", color: "red" },
];

export const SEX = [
  { value: 1, label: "男" },
  { value: 0, label: "女" },
];
export const APPLYSTATE = [
  { value: 0, label: "审核中" },
  { value: 1, label: "已通过" },
  { value: 2, label: "未通过" },
];
export const STORETYPE = [
  { value: 1, label: "直营 " },
  { value: 2, label: "分销商 " },
  { value: 3, label: "经销商  " },
  { value: 4, label: "其他 " },
];
export const OssImagePrefix =
  "http://dongfang-yz-shanghai.oss-cn-shanghai.aliyuncs.com";
