import { queryMenus } from "@/api/user/user";
import { message } from "ant-design-vue";
import { LayoutBlock, LayoutUser, LayoutBase } from "@/layouts";
import { generator } from "./handler";
import { listToTree } from "@/utils/util";

import common from "./common";
import template from "./template";

// 前端路由表
export const routeComponents = {
  LayoutBlock,
  LayoutUser,
  LayoutBase,

  // 自定义的动态路由
  workplace: () => import("@/views/dashboard/workplace"),
  A: () => import("@/views/user/user-manage/index.vue"),
  B: () => import("@/views/user/user-manage/userDetail.vue"),
  C: () => import("@/views/user/creater-manage/index.vue"),
  D: () => import("@/views/user/creater-manage/createrPlatformDetail.vue"),
  E: () => import("@/views/user/platform-manage/index.vue"),
  F: () => import("@/views/user/platform-manage/merchantPlatformDetail.vue"),
  G: () => import("@/views/user/store-manage/index.vue"),
  H: () => import("@/views/user/creater-manage/createrPlatformDetail.vue"),
  I: () => import("@/views/user/resident-audit/index.vue"),
  J: () => import("@/views/user/resident-audit/detail.vue"),
  K: () => import("@/views/user/resident-audit/insert.vue"),
  L: () => import("@/views/user/oneself-audit/index.vue"),
  M: () => import("@/views/user/oneself-audit/detail.vue"),
  N: () => import("@/views/user/oneself-audit/insert.vue"),
  O: () => import("@/views/user/enterprise-audit/index.vue"),
  P: () => import("@/views/user/enterprise-audit/detail.vue"),
  Q: () => import("@/views/user/enterprise-audit/insert.vue"),
  R: () => import("@/views/content/video-manage"),
  S: () => import("@/views/content/video-manage/creatorVideoDynamicDetail"),
  T: () => import("@/views/content/video-manage/index.vue"),
  U: () => import("@/views/content/video-manage/creatorVideoDynamicDetail"),
  V: () => import("@/views/content/article/index"),
  W: () => import("@/views/content/article/detail"),
  X: () => import("@/views/content/production/index"),
  Y: () => import("@/views/content/production/detail"),
  Z: () => import("@/views/content/complaint/index"),
  AA: () => import("@/views/content/complaint/detail"),
  AB: () => import("@/views/content/interestTag/index.vue"),
  AC: () => import("@/views/set/platform-type/index.vue"),
  AD: () => import("@/views/set/platform-http/index.vue"),
  AE: () => import("@/views/set/platform-http/editPlat.vue"),
  AF: () => import("@/views/set/employee/list.vue"),
  AG: () => import("@/views/set/employee/edit.vue"),
  AH: () => import("@/views/set/role/list.vue"),
  AI: () => import("@/views/set/role/add.vue"),
  AJ: () => import("@/views/set/role/edit.vue"),
  AL: () => import("@/views/user/claimManagement/index.vue"),
  AM: () => import("@/views/user/creater-manage/fans.vue"),
  AN: () => import("@/views/user/platform-manage/fans.vue"),
  AO: () => import("@/views/content/video-manage/brushData.vue"),
  AK: () => import("@/views/logger/operationLog/index.vue"),
  AP: () => import("@/views/user/brandManage/index"),
  AQ: () => import("@/views/user/brandManage/detail"),
};

// 动态生成菜单
export const generatorDynamicRoutes = () => {
  return queryMenus().then((res) => {
    let newData = res.data || [];
    let f = newData.find((item) => item.component === "C");
    console.log(f);
    let data = [];
    newData.forEach((item) => {
      if (item.meta.menuType === "M") {
        item.name = null;
      } else if (item.meta.menuType === "C") {
        item.name = null;
        item.meta.hideChildren = true;
        delete item.meta["icon"];
      } else if (item.meta.menuType === "V") {
        item.meta.hidden = item.hidden;
        delete item["hidden"];
      }
      if (
        item.path == "insert" &&
        item.parentId == 70050 &&
        item.component != "K"
      ) {
        item.component = "K";
      }
      data.push(item);
    });
    let root = [
      {
        path: "/",
        component: "LayoutBase",
        redirect: "/dashboard/workplace",
        children: [
          {
            path: "dashboard",
            component: "LayoutBlock",
            title: "首页",
            icon: "menu-home",
            children: [
              {
                path: "workplace",
                component: "workplace",
                title: "工作台",
              },
              // {
              //   path: "fans",
              //   component: "fans",
              //   title: "刷粉丝",
              // },
            ],
          },
        ],
      },
    ];

    let template = listToTree(data, {});
    // 将通用路由添加到根路由下面
    const rootRoute = root.find((item) => item.path === "/") || {};
    if (rootRoute.children) {
      rootRoute.children = rootRoute.children.concat(template, common);
    }
    const menus = generator(root, routeComponents);
    //console.log('-------menus----------->', menus)
    return menus;
  });
};
