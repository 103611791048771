import {v4} from 'uuid'

/**
 * 设置参数到localstorage
 * @param key
 * @param value
 */
export function getItem(key) {
  return localStorage.getItem(key);
}

/**
 * 设置参数到localstorage
 * @param key
 * @param value
 */
export function setItem(key, value) {
  localStorage.setItem(key, value);
}

/**
 * 设置参数到localstorage
 * @param key
 * @param value
 */
export function getJsonItem(key) {
  return JSON.parse(localStorage.getItem(key));
}

/**
 * 设置参数到localstorage
 * @param key
 * @param value
 */
export function setJsonItem(key, value) {
  localStorage.setItem(key, JSON.stringify(value));
}

// 手机号校验
export function testMobile(value) {
  return /^[1]([3-9])[0-9]{9}$/.test(value)
}

// 密码校验
// 8至16位大小写字母数字下划线特殊字符组合
export function testPassword(value) {
  return /^[a-zA-Z0-9_`~!@#$%^&*()_+<>?:"{},.\\/;'[\]]{8,16}$/.test(value)
}

// 身份证校验
export function testIdentity(value) {
  return /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}$)/.test(value)
}

/**
 * 日期格式化
 * @param time
 * @param pattern
 * @returns {string|null}
 */
export function parseTime(time, pattern) {
  if (arguments.length === 0 || !time) {
    return null
  }
  const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
      time = parseInt(time)
    } else if (typeof time === 'string') {
      time = time.replace(new RegExp(/-/gm), '/').replace('T', ' ').replace(new RegExp(/\.[\d]{3}/gm), '');
    }
    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return time_str
}

/**
 * 表单重置
 * @param refName
 */
export function resetForm(refName) {
  if (this.$refs[refName]) {
    this.$refs[refName].resetFields();
  }
}

/**
 * 判断是否空['', null, undefined]，空则给默认值， 0，'0'，false表示有值
 *
 * @param val
 * @param defaultVal
 */
export function ifEmpty(val, defaultVal) {
  return isEmpty(val) ? defaultVal : val;
}

/**
 * 判断是否空['', null, undefined]，空则给默认值， 0，'0'，false表示有值
 *
 * @param val
 */
export function isEmpty(val) {
  return (val === undefined || val == null || val === '')
}

/**
 * 判断一个值是否是空对象
 * @param val
 * @returns {boolean}
 */
export function isEmptyObject(val) {
  return Object.keys(val).length === 0
}


/**
 * 判断一个数组是否是空数组
 * @param val
 * @returns {boolean}
 */
export function isEmptyArray(val) {
  if (val === undefined || val == null) return true
  if(Array.isArray(val)) {
    return val.length <= 0
  }
  throw new Error('parameter val is not a array')
}

/**
 * 判断一个数组是否不为空
 * @param val
 * @returns {boolean}
 */
export function isNotEmptyArray(val) {
  return !isEmptyArray(val)
}

/**
 * 是否是json对象
 * @param obj
 * @return {boolean}
 */
export function isJson(obj) {
  if(typeof obj == 'object') {
    return true
  }
  return false
}
/**
 * 表单验证失败结果
 * @param obj
 * @return {boolean}
 */
export function isValidFalse(obj) {
  if(isJson(obj) && obj.valid === false) {
    return true
  }
  return false
}

/**
 * 拼接两个path，如果有/，则处理后拼接
 * 返回：/abc/cde 结构
 * 返回：/abc 结构
 * 返回：/ 结构
 * @param path1
 * @param path2
 */
export function concatPath(path1, path2) {
  path1 = path1 || '';
  path2 = path2 || '';
  let str = path1 + "/" + path2;
  str = str.replaceAll(/\/+/g, '/')
  if (str.length > 1 && str.charAt(str.length - 1) === '/') {
    str = str.slice(0, -1);
  }
  return str
}

/**
 * 去掉一头一尾的指定字符
 *
 * @param str
 * @param c
 * @returns {*|string}
 */
function sliceStr(str, c) {
  str = str || '';
  // 截取头
  while (str.charAt(0) === c) {
    str = str.slice(1);
  }
  // 截取尾
  while (str.charAt(str.length - 1) === c) {
    str = str.slice(0, -1);
  }
  return str;
}

/**
 * 数据对象合并，空则返回{}，一个则复制返回，两个，则从右向左复制，不改变原数据。
 *
 * @param arguments
 * @returns {*}
 */
export function mergeObject() {
  let ret = {};
  let len = arguments.length;
  if (len === 0) return ret;
  // 定义闭包
  let merge = function (source, target) {
    for (let k in target) {
      try {
        if (target[k]) {
          if (target[k].constructor == Object) {
            source[k] = this.merge(source[k], target[k]);
          } else {
            source[k] = target[k];
          }
        } else {
          source[k] = target[k];
        }
      } catch (e) {
        source[k] = target[k];
      }
    }
    return source;
  };
  for (let i = 0; i < len; i++) {
    let item = arguments[i];
    ret = merge(ret, item);
  }
  return ret;
}

/**
 * 数组转树结构
 * @param {Array} arr 源数据[{id, parentId, xxx},{id, parentId, xxx},]
 * @param {JSON} opts {idField,pidField,rank} 默认用id和parentId两个字段
 */
export function listToTree(arr, opts) {
  let data = JSON.stringify(arr);
  data = JSON.parse(data)
  let root = [];

  let idField = 'id';
  let pidField = 'parentId';
  if (opts && opts.idField) {
    idField = opts.idField;
    pidField = opts.pidField;
  }

  // list 转map
  let map = {};
  data.forEach(item => {
    map[item[idField]] = item;
  });
  // 循环，暂时不考虑回环情况（节点的id=parentId）
  data.forEach(node => {
    let pid = node[pidField] || '0';
    // 查找当前节点的父节点
    if (map[pid]) {
      map[pid].children = map[pid].children || [];
      map[pid].children.push(node);
    } else {
      root.push(node);
    }
  });
  return root;
}

/**
 * 树结构转数组，把children拿出来
 * @param {Array} arr 源数据[{id, name, path,children:[{}]},{id, name,path, children:[{}]},]
 * @param {JSON} opts {idField,pidField,rank} 默认用id和parentId两个字段
 * @return [{id, name, parentId, path}, {id, name, parentId, path}]
 */
export function treeToList(arr) {
  let data = JSON.stringify(arr);
  data = JSON.parse(data)

  // 定义闭包
  let loop = function (data) {
    let list = [];
    data.forEach(node => {
      if (node.children && node.children.length > 0) {
        let children = node.children;
        delete node['children']
        list.push(node);
        list.push(...loop(children))
      } else {
        list.push(node);
      }
    });
    return list;
  }
  return loop(data);
}

/**
 * 数组转Map
 * @param {Array} arr 源数据[{id, parentId, xxx},{id, parentId, xxx},]
 * @param {JSON} opts {idField,pidField,rank} 默认用id和parentId两个字段
 */
export function listToMap(arr, opts) {
  let data = JSON.stringify(arr);
  data = JSON.parse(data)

  let idField = 'id';
  if (opts && opts.idField) {
    idField = opts.idField;
  }
  let map = {};
  data.forEach(node => {
    map[node[idField]] = node;
  })
  return map;
}

/**
 * 遍历json对象，返回label
 *
 * @param {JSON} data eg: {value:'1', label:'这是1', children:[{value:'11', label:'this 11'},{value:'12', label:'this 12'}]},
 * @param {String} splitChar label的分割符 eg: '/'
 * @return json value/label的对象结构，eg：{'1,11': '这是1/this 11', '1,12': '这是1/this 12'}
 */
export function concatMapTreeLabel(data, splitChar) {
  if (isEmpty(data)) {
    return null;
  }
  splitChar = splitChar || '/';
  // 无返回值得递归，遍历到叶子节点，处理
  let loop = function (items, map, retMap) {
    retMap = retMap || {};
    items.forEach(item => {
      let itemMap = {k: map.k, v: map.v};

      itemMap.k = isEmpty(itemMap.k) ? item.value : (itemMap.k + ',' + item.value);
      itemMap.v = isEmpty(itemMap.v) ? item.label : (itemMap.v + splitChar + item.label);

      if (item.children && item.children.length > 0) {
        loop(item.children, itemMap, retMap);
      } else {
        retMap[itemMap.k] = itemMap.v;
      }
    });
    return retMap;
  };
  let retMap = {};
  loop(data, {k: '', v: ''}, retMap);
  return retMap;
}

/**
 * 日期月计算
 *
 * @param date
 * @param diff
 * @returns date
 */
export function monthCompute(date, diff) {
  date.setMonth(date.getMonth() + diff);
  return date;
}

/**
 * 获取连续天
 *
 * @param strStart
 * @param strEnd
 * @returns {[]|*[]}
 */
export function getLinearDayList(strStart, strEnd) {
  if(!strStart || !strEnd) {
    return []
  }
  let s = new Date(strStart)
  let e = new Date(strEnd)
  let ret = []
  let endTime = e.getTime()
  while(s.getTime() <= endTime) {
    ret.push(parseTime(s, '{y}-{m}-{d}'))
    s.setDate(s.getDate() + 1)
  }
  return ret;
}

/**
 * 获取连续月
 *
 * @param strStart
 * @param strEnd
 * @returns {[]|*[]}
 */
export function getLinearMonthList(strStart, strEnd) {
  if(!strStart || !strEnd) {
    return []
  }
  let s = new Date(strStart)
  let e = new Date(strEnd)
  let ret = []
  let endTime = e.getTime()
  while(s.getTime() <= endTime) {
    ret.push(parseTime(s, '{y}-{m}'))
    s.setMonth(s.getMonth() + 1)
  }
  return ret;
}

/**
 * 金额单位转换
 *
 * @param val 7287233.23
 * @param type {w | y} 转换目标， w万元，y亿元
 * @param decimal 小数位数
 */
export function convertCurrency(val, type, decimal) {
  let currencyUnit = {w: 10000, y: 100000000}
  if (isEmpty(val)) return null
  let num = Number(val)
  if(num == 0) return val
  decimal = decimal || 0
  num = num / currencyUnit[type]
  num = num.toFixed(decimal)
  return num
}

/**
 * 金额格式化
 * @param val
 */
export function currencyFormat(val) {
  if(isEmpty(val)) {
    return null
  }
  let ret = ""
  let str = (val + '').split(".")
  let arrInteger = str[0].split("")
  let strDecimal = ""
  if(str.length > 1) {
    strDecimal = "." + str[1]
  }
  arrInteger.reverse().forEach((c, index) => {
    if(index !== 0 && index % 3 === 0) {
      ret = c + "," + ret
    } else {
      ret = c + ret
    }
  })
  ret = ret + strDecimal
  return ret
}

/**
 * 文件名变更
 *
 * @param fileName
 * @returns 获取去掉扩展名
 */
export function extName(fileName) {
  let f = ["", ""] // 0是文件名，1是文件扩展名
  if (fileName) {
    let index = fileName.lastIndexOf(".")
    f[0] = fileName.substr(0, index)
    f[1] = fileName.substr(index)
  }
  return f;
}

/**
 * 文件名变更
 *
 * @param fileName  test.png
 * @param newFileName xuyd，空则用uuid代替
 * @returns 新的文件名 xuyd.png
 */
export function convertFileName(fileName, newFileName) {
  let f = extName(fileName)
  if (newFileName) {
    return newFileName + f[1]
  } else {
    let newUuid = v4()
    newUuid = newUuid.replace(/[-]/g, '')
    return newUuid + f[1]
  }
}

/**
 * 转数组
 * @param value
 * @returns {string[]}
 */
export function toArray(value) {
  let f = Array.isArray(value) ? value : (value + '').split(',')
  return f;
}

/**
 * 验证富文本是否为空
 *
 * @param val
 * @returns {boolean}
 */
export function checkEmptyRichText(val) {
  // console.log("------richText------>", val)
  if(isEmpty(val)) {
    return true
  }
  val = val.replace(/<[^<p>]+>/g, '')
  val = val.replace(/<[^</p>]+>/g, '')
  val = val.replace(/<[^<br>]+>/g, '')
  val = val.replace(/&nbsp;/gi, '')
  if(isEmpty(val)) {
    return true
  }
  let reg = new RegExp('[]+$')
  return reg.test(val)
}

