import "core-js/stable";
import "regenerator-runtime/runtime";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import Vue from "vue";
import moment from "moment";
import * as utils from "@/utils/utils";
import * as util from "@/utils/util";
import * as constant from "@/utils/const";
import api from "@/api";
import request from "@/utils/request";
import VueViewer from "v-viewer";

import "moment/locale/zh-cn";

import "./extends/use";
import "./extends/directive";
import "./extends/prototype";

import store from "./store";
import router from "./router";
import App from "./app";

// import '../mock/mock.js';

import "./assets/icons/index"; // svg-icon

import "@/components/nprogress.less";
import "@wytxer/style-utils/lib/common.less";
import "viewerjs/dist/viewer.css";
// TextAreaEx
import TextArea from "@/components/TextArea";

// 对话框组件等
import Modal from "@/components/Modal";

// 字典标签组件
import DictTag from "@/components/DictTag";

// 字典数据组件
import DictData from "@/components/DictData";
import CodeTag from "@/components/Code";

// PageHeader组件
import PageHeader from "@/components/PageHeader";

// 分页组件
import Pagination from "@/components/Pagination";
Vue.use(Antd);
Vue.component("PageHeader", PageHeader);

Vue.component("Pagination", Pagination);
Vue.component("CodeTag", CodeTag);
Vue.component("DictTag", DictTag);
DictData.install();

Vue.component("TextAreaEx", TextArea);
Vue.use(VueViewer);

moment.locale("zh-cn");
import { ossUrl } from "@/components/AliOss/OssClientExCopy.js";
Vue.prototype.ossUrl = ossUrl;
Vue.prototype.$moment = moment;
Vue.prototype.$utils = utils;
Vue.prototype.$util = util;
Vue.prototype.$const = constant;
Vue.prototype.$api = api;
Vue.prototype.$request = request;

Vue.prototype.$modal = Modal;

// if (process.env.VUE_APP_USE_MOCKER === 'false')
// {
//   require('../mock/index')
// }

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
