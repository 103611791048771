import store from '@/store/index'

/**
 * dict的元数据定义
 * @type {{metas: {"*": {request(*): *, valueField: string, dictType: null, labelField: string}}}}
 */
export const options = {

  // 元数据定义
  // metas:{'*':{}, 'prod_state':{}}，采用这种定义，便于定义扩展，
  // '*'表示默认dictType的定义，如果有特殊可以单独定义，在Dict.parse里面会根据dictType取出
  // 特殊定义：metas:{'prod_state':{dictType: sys_yes_no, labelField, valueField, request}, xx}
  metas: {
    '*': {
      dictType: null,
      dataType: Number, // 值类型
      isTree: false,    // 是否是树形字典
      treeLevel: null,  // 是树形字典时候，获取节点级别
      labelField: 'dictText',
      valueField: 'dictValue',
      // 字典数据转换器，方法为function(dictMeta: DictMeta, response: Object): Object
      fieldNameConverter: null,
    },
  },
  requestDictBatch(dictMetas) {
    if (dictMetas && dictMetas.length > 0) {
      console.log('批量加载数据字典：', dictMetas);
      return store.dispatch('doSelectDictBatch', dictMetas).then(data => data);
    }
    return null;
  },
  requestDictTreeBatch(dictTreeMetas) {
    if (dictTreeMetas && dictTreeMetas.length > 0) {
      console.log('批量加载树结构数据字典：', dictTreeMetas);
      return store.dispatch('doSelectDictTreeBatch', dictTreeMetas).then(data => data);
    }
    return null;
  },
}

export default options
