import { login, logout, queryUser } from "@/api/user/user";
import { removeToken, setToken } from "@/utils/auth.js";
import { oss } from "@/api/user/user";
import * as Encryptor from "@/utils/encryptor";
const initState = {
  // 用来做是否已登录的标识，前端自己维护这个状态，登录成功后设置为 true，登出之后设置为 false
  logged: false,
  // 这里返回具体的权限点信息，然后通过 v-action 或者 $auth 进行权限判断
  // ['add', 'delete', 'edit']
  permissions: [],
  // 用户名称
  nickname: "",
  token: "",
  businessType: 4,
};

const user = {
  state: {
    ...initState,
    OSSConfig: {
      region: "oss-cn-shanghai",
      bucket: "dongfang-yz-shanghai",
    },
  },

  mutations: {
    SET_USER: (state, data) => {
      //console.log('data', data)
      Object.entries(data).forEach(([key, value]) => {
        state[key] = value;
      });
      state.logged = true;
    },
    INIT_STATE: (state, data) => {
      Object.entries(initState).forEach(([key, value]) => {
        state[key] = value;
      });
      state.logged = false;
    },
    SET_TOKEN: (state, token) => {
      state.token = token;
      //console.log('state.token', state.token)
      // localStorage.setItem("token", token)
      setToken(state.token);
    },
    SET_OSS: (state, { accessKeyId, accessKeySecret }) => {
      state.OSSConfig.accessKeyId = accessKeyId;
      state.OSSConfig.accessKeySecret = accessKeySecret;
    },
  },

  actions: {
    // 登录
    login({ commit }, data) {
      // return login(data)
      //   .then(res => {
      //     const data = res.data || {}
      //     if (res.code === 200) {
      //       commit('SET_USER', data)
      //       commit('SET_TOKEN', data.token)
      //       const { query } = this.$route
      //       this.$router.push(query.redirect || '/')
      //       return res
      //     }
      //   })
      return new Promise((resolve, reject) => {
        // 调用接口
        login(data)
          .then((res) => {
            // dispatch('doClearSession');
            const data = res.data || {};
            // const { query } = this.$route
            commit("SET_USER", data);
            commit("SET_TOKEN", data.token);
            resolve();
          })
          .catch((err) => {
            //console.error(err)
            commit("SET_USER", "");
            commit("SET_TOKEN", "");
            removeToken(data);
            reject(err);
          });
      });
    },
    getOss({ commit, state }) {
      return new Promise((resolve, reject) => {
        // 调用接口
        oss()
          .then((res) => {
            const accessKeyId = Encryptor.decryptOSS(res.data.keyId);
            const accessKeySecret = Encryptor.decryptOSS(res.data.keySecret);
            commit("SET_OSS", {
              accessKeyId: accessKeyId,
              accessKeySecret: accessKeySecret,
            });
            resolve(state.OSSConfig);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    // 登出
    logout({ commit, dispatch, data }) {
      return logout(data).then((res) => {
        commit("setToken", "");
        removeToken(data);
      });
    },
    // 获取用户信息
    queryUser({ commit }, data) {
      return queryUser(data).then((res) => {
        const data = res.data || {};
        commit("SET_USER", data);
        return data;
      });
    },
  },
};

export default user;
