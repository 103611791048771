import request from "@/utils/request";

const baseUrl = `${process.env.VUE_APP_API_API}/user`;

const adminUrl = `${process.env.VUE_APP_API_API}/admin`;
// 登录
export function login(data) {
  return request({
    method: "post",
    url: `${baseUrl}/platformLogin/passwordLogin`,
    headerType: "json",
    data,
  });
}

// 登出
export function logout(data) {
  return request({
    method: "post",
    url: `${baseUrl}/out/loginout`,
    data,
  });
}

// 获取用户信息
export function queryUser(data) {
  return request({
    method: "get",
    url: `${adminUrl}/account/login-info`,
    data,
    params: {
      t: Date.now(),
    },
  });
}

// 获取菜单树
export function queryMenus() {
  let promise = null;
  // promise = new Promise(resolve => {
  //   resolve({data: data, code: 0})
  // });

  promise = request({
    method: "post",
    url: `${adminUrl}/permission/menus`,
    data: { moduleType: 4 },
  });
  return promise;
}

// 获取短信验证码
export function getSmsCode(data) {
  return request({
    method: "post",
    closeAutoTips: true,
    url: "",
    mockUrl: "/mock/user/sms/send",
    headerType: "json",
    data,
  });
}

// 忘记密码（用于未登录）
export function resetPassword(data) {
  return request({
    method: "post",
    closeAutoTips: true,
    url: `${adminUrl}/account/reset-password`,
    headerType: "json",
    data,
  });
}

// 重置密码（用于已登录）
export function modifyPassword(data) {
  return request({
    method: "post",
    closeAutoTips: true,
    url: `${adminUrl}/account/update-password`,
    headerType: "json",
    data,
  });
}

// 注册
export function register(data) {
  return request({
    method: "post",
    closeAutoTips: true,
    url: "",
    mockUrl: "/mock/login/register",
    headerType: "json",
    data,
  });
}

// 获取用户列表
export function queryAllUser(data) {
  return request({
    url: `${adminUrl}/user/getUserPage`,
    method: "post",
    data,
  });
}

// 获取用户详情
export function queryUserDetail(data) {
  return request({
    url: `${adminUrl}/user/queryUserByUserId`,
    method: "post",
    headerType: "json",
    data,
  });
}

// 正常/禁用
export function updateUser(data) {
  return request({
    method: "post",
    url: `${adminUrl}/user/update`,
    headerType: "json",
    data,
  });
}

export function oss() {
  return request({
    method: "get",
    url: `${baseUrl}/oss/getOssConfig`,
    headerType: "json",
  });
}
