
<template>
  <span :style="{color:text['color']}">{{ text[label] }}</span>
</template>
<script>
export default {
  props: {
    codeList: {
      type: Array,
      default: () => {
        return []
      }
    },
    code: {
      type: [String, Number, Object]
    },
    value: {
      type: String,
      default: 'value'
    },
    label: {
      type: String,
      default: 'label'
    }
  },
  data() {
    return {}
  },
  computed: {
    text() {
      let name = ''
      let color = '#333'
      let obj = this.codeList.find((item) => item[this.value] === this.code)
      if (obj) {
        name = obj[this.label]
      }
      return obj
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
  