import LayoutUser from '@/layouts/user.vue'
import { LayoutBlock, LayoutBase } from "@/layouts";
const routes = [{
  path: '/login',
  component: LayoutUser,
  children: [{
    path: 'login',
    component: () => import('@/views/login/login.vue'),
    meta: {
      title: '登录'
    }
  }, {
    path: 'reset-password',
    component: () => import('@/views/login/reset-password.vue'),
    meta: {
      title: '重置密码'
    }
  }, {
    path: 'register',
    component: () => import('@/views/login/register.vue'),
    meta: {
      title: '注册'
    }
  }, {
    path: '*',
    component: () => import('@/views/common/404.vue'),
    meta: {
      title: '404',
      // 如果是用户模块对 404 的引用，通过这个值自定义样式，改一下背景色
      user: true
    },
    
  }]
},
// {
//   path: "user-manage",
//   component: LayoutBlock,
//   meta: {
//     title: "用户",
//     icon: "menu-user",
//   },
//   children: [

//     {
//       path: "createrManage",
//       component: LayoutBlock,
//       redirect: "createrManage/index",
//       meta: {
//         title: "创作者平台管理",
//         hideChildren: true,
//       },
//       children: [
//         {
//           path: "fans",
//           component: () => import("@/views/user/fans/index.vue"),
//           meta: {
//             title: "刷粉丝",
//             hidden: true,
//           },
//         },
//       ],
//     },
//     {
//       path: "platformManage",
//       component: LayoutBlock,
//       redirect: "platformManage/index",
//       meta: {
//         title: "商家平台管理",
//         hideChildren: true,
//       },
//       children: [
//         {
//           path: "fans",
//           component: () => import("@/views/user/fans/index.vue"),
//           meta: {
//             title: "刷粉丝",
//             hidden: true,
//           },
//         },
//       ],
//     },
   
//   ]
// },
]

export default routes
