import axios from "axios"
import {message, Modal} from 'ant-design-vue'
import {getToken} from '@/utils/auth.js'
import store from '../store/index'

const qs = require('qs');

let isShowLoginTip = false;

/**
 * 响应数据状态
 * @type {{SUCCESS: 200, ERROR: number, UNSIGNED: 401}}
 */
const ResponseCode = {
  success: {text: 'SUCCESS', value: '200'},
  unsigned: {text: 'UNSIGNED', value: '20004'},
  error: {text: 'ERROR', value: '1'},
};

/**
 * 创建单例
 * @type {AxiosInstance}
 */
const instance = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API,
  baseURL: '',
  timeout: 120000
});

/**
 * post的表单提交
 *
 * @param url
 * @param jsonData
 */
instance['postForm'] = function (url, jsonData) {
  return instance.post(url, qs.stringify(jsonData), {headers: {'Content-Type': 'application/x-www-form-urlencoded'}});
}

/**
 * post的表单提交(包含文件，且服务端采用@RequestPart接收)
 *
 * @param url
 * @param formJsonData{infoForm:{a:1,b:2}} 参数对象和参数body内容
 * @param fileObjectList 文件对象，支持多字段，多个文件上传{fileParam:[file],fileParam2:file}
 */
instance['postFormData'] = function (url, formJsonData, fileObjectList) {
  let data = new FormData();
  formJsonData = formJsonData || {}
  let keys = Object.keys(formJsonData)
  keys.forEach(formName => {
    data.append(formName, new Blob([JSON.stringify(formJsonData[formName])], {type: 'application/json'}))
  })

  // eleUI fileUpload’是fileObject is raw
  // ant D fileUpload’是fileObject is originFileObj
  // data.append(propFile.fileParam, propFile.fileList[0].raw);
  fileObjectList = fileObjectList || {}
  let fileKeys = Object.keys(fileObjectList)
  fileKeys.forEach(key => {
    data.append(key, fileObjectList[key]);
  })

  return instance.post(url, data, {headers: {'Content-Type': 'multipart/form-data'}});
}


// 不需要加载全局loading的接口
const ignoreApi = [
  '/business/tab/queryTab',
]
function loading(config) {
  let url = config.url
  if(!ignoreApi.includes(url)) {
    store.dispatch('doLoading', true)
  }
}

/**
 * 请求拦截器
 */
instance.interceptors.request.use(
    config => {
      console.debug("API接口请求==============>", config);
      // 让每个请求携带自定义token 请根据实际情况自行修改
      let t = getToken();
      // t = t ? ('Bearer ' + getToken()) : '';
      t = t ? getToken() : '';
      // config.headers['Authorization'] = t;
      config.headers['Authorization-admin'] = t;
      // config.headers['UserAccount'] = store.getters.getUserAccount;
      loading(config)
      return config
    },
    error => {
      // error request
      console.log("请求终止")
      store.dispatch('doLoading', false)
      return Promise.reject(error)
    }
);

/**
 * 响应拦截器
 */
instance.interceptors.response.use(
    response => {
      store.dispatch('doLoading', false)
      let result = response.data
      // 二进制数据则直接返回
      // mock的时候，需要判断的是，response.config.responseType ===  'blob'但是里面new Blob后是错误的。
      // 所以要下载文件的的话，需要在main.js去掉mock，否则data=3956B，但是new Blob后是6789B
      if (response.request.responseType === 'blob' || response.request.responseType === 'arraybuffer'
          || response.config.responseType === 'blob' || response.config.responseType === 'arraybuffer'
      ) {
        return response
      }

      if (process.env.VUE_APP_ENV !== "production") {
        try {
          console.log('API接口返回：【' + response.config.url + "】=====>", JSON.parse(JSON.stringify(result)));
        } catch (err) {
          console.log(err)
        }
      }

      if (result.code == ResponseCode.success.value) {
        return Promise.resolve(result.data)
      } else if (result.code == ResponseCode.unsigned.value
          ||result.code == ResponseCode.unsigned.value) {
        handleLogout();
        return Promise.reject(result.message)
      } else {
        console.error('【ERROR】' + result.message);
        message.error(result.message)
        // return Promise.reject(ResponseCode.error.text)
        return Promise.reject(result)
      }
    },
    error => {
      store.dispatch('doLoading', false)
      if (error.response) {
        let status = error.response.status
        let resp = error.response.data;

        if (status === 401) {
          handleLogout();
          return Promise.reject(resp.message)
        } else {
          console.log('服务器异常:' + error)
          // 弹出消息
          message.error(error.message)
          return Promise.reject(ResponseCode.error.text)
        }
      } else {
        if (error.code == 'ECONNABORTED' && error.message) {
          // timeout
          console.log('服务器异常:' + error)
          // 弹出消息
          message.error("服务器请求超时")
          return Promise.reject(ResponseCode.error.text)
        }
      }
    }
);

/***
 * 处理logout
 */
function handleLogout() {
  // jum to login
  if (!isShowLoginTip) {
    isShowLoginTip = true;

    Modal.confirm({
      title: '系统提示',
      content: '登录状态已过期，您可以继续留在该页面，或者重新登录',
      okText: '重新登录',
      cancelText: '取消',
      onOk() {
        // store.dispatch('doLogout').then(() => {
          // 登录页面不跳转到登录
          // hash: ""
          // host: "localhost:8080"
          // hostname: "localhost"
          // href: "http://localhost:8080/system/log/detail?id=29859"
          // origin: "http://localhost:8080"
          // pathname: "/system/log/detail"
          // port: "8080"
          // protocol: "http:"
          // reload: ƒ reload()
          // replace: ƒ replace()
          // search: "?id=29859"
          if (window.location.pathname.indexOf("/login/login") !== 0) {
            let redirectUrl = window.location.pathname + (window.location.search ? window.location.search : '');
            location.href = '/login/login?redirect=' + encodeURI(redirectUrl);
          }
        // });
      },
      onCancel() {
        isShowLoginTip = false;
      }
    })
  }
}

/**
 * 通用下载方法（post/json提交）
 * @param url
 * @param jsonData
 * @param filename
 * @returns {Promise<unknown>}
 */
let downloadLoadingInstance;

// 通用下载方法 表单参数提交
export function downloadForm(url, jsonData) {
  // downloadLoadingInstance = Loading.service({text: "正在下载数据，请稍候", spinner: "el-icon-loading", background: "rgba(0,0,0,0.7)",})
  downloadLoadingInstance = message.loading("正在下载数据，请稍候", 0)
  return instance.post(url, qs.stringify(jsonData), {
    responseType: 'blob',
    timeout: 300000
  }).then(async (response) => {
    downloadResponse(response, null);
    downloadLoadingInstance.close();
  }).catch((err) => {
    message.error('下载文件出现错误，请联系管理员！')
    downloadLoadingInstance.close();
  })
}

// 通用下载方法 JSON参数提交
export function download(url, jsonData, filename) {
  // downloadLoadingInstance = message.loading().service({text: "正在下载数据，请稍候", spinner: "el-icon-loading", background: "rgba(0,0,0,0.7)",})
  downloadLoadingInstance = message.loading("正在下载数据，请稍候", 0)
  return instance.post(url, jsonData, {
    responseType: 'blob',
    timeout: 300000
  }).then(async (response) => {
    downloadResponse(response, filename);
    downloadLoadingInstance.close();
  }).catch((err) => {
    message.error('下载文件出现错误，请联系管理员！')
    downloadLoadingInstance.close();
  })
}

// 通用下载方法回调方法
function downloadResponse(response, filename) {
  let data = response.data;
  // Content-Disposition: attachment;filename= + fileName
  console.log('API接口接收文件对象' + response.headers['content-disposition'])
  let contentDisposition = response.headers['content-disposition'];
  if (contentDisposition) {
    filename = contentDisposition.split(";")[1].split("=")[1];
    filename = decodeURI(filename);
  }

  const isLogin = blobValidate(data);
  if (isLogin) {
    const blob = new Blob([data])
    // let blob = new Blob([data], {type: "application/vnd.ms-excel"});
    // let blob = new Blob([data], {type: "application/octet-stream"});
    saveAs(blob, filename)
  } else {
    const resText = data.text();
    const result = JSON.parse(resText);
    if (result.code === ResponseCode.success.value) {
      result.message && message.success(result.message)
    } else {
      result.message && message.error(result.message)
    }
  }
}

// 验证是否为blob格式
async function blobValidate(data) {
  try {
    const text = await data.text();
    JSON.parse(text);
    return false;
  } catch (error) {
    return true;
  }
}


/**
 * 参数处理
 * @param {*} params  参数
 */
function tansParams(params) {
  let result = ''
  for (const propName of Object.keys(params)) {
    const value = params[propName];
    let part = encodeURIComponent(propName) + "=";
    if (value !== null && typeof (value) !== "undefined") {
      if (typeof value === 'object') {
        for (const key of Object.keys(value)) {
          if (value[key] !== null && typeof (value[key]) !== 'undefined') {
            let params = propName + '[' + key + ']';
            let subPart = encodeURIComponent(params) + "=";
            result += subPart + encodeURIComponent(value[key]) + "&";
          }
        }
      } else {
        result += part + encodeURIComponent(value) + "&";
      }
    }
  }
  return result
}


export default instance;
