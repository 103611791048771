<template>
  <div class="textarea-container">
    <a-textarea
      v-model="content"
      class="textarea"
      :placeholder="placeholder"
      :maxLength="maxLength"
    >
    </a-textarea>
    <span class="input-count" v-if="showWordLimit">{{ contentWordLimit }}</span>
  </div>
</template>

<script>
export default {
  name: "TextAreaEx",
  data() {
    return {
      content: this.value,
    };
  },
  // 双向绑定对象和发送的默认事件
  model: {
    prop: "value",
    event: "blur",
  },
  computed: {
    contentWordLimit() {
      const len = this.content ? this.content.length : 0;
      return len + "/" + this.maxLength;
    },
  },
  props: {
    value: String,
    placeholder: String,
    maxLength: Number,
    showWordLimit: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    value(val) {
      this.content = val;
    },
    content(val) {
      this.$emit("blur", val);
    },
  },
};
</script>

<style lang="less" scoped>
.textarea-container {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
  vertical-align: bottom;
  font-size: 14px;

  .textarea {
    width: 100%;
    height: 100%;
  }

  .input-count {
    color: #000000a6;
    position: absolute;
    font-size: 14px;
    bottom: 5px;
    right: 10px;
  }
}
</style>
