<template>
  <div class="pagination-container">
    <a-config-provider :locale="locale">
      <a-pagination
          class="fl"
          v-show="total > 0"
          v-model="_pageNum"
          :pageSize.sync="_pageSize"
          :total="total"
          :showTotal="(total) =>`总共 ${total} 条`"
          :defaultPageSize="5"
          :pageSizeOptions="['5', '10', '15', '20']"
          show-quick-jumper
          show-size-changer
          @change="pageChangeHandler"
          @showSizeChange="sizeChangeHandler"
      />
    </a-config-provider>
  </div>
</template>

<script>
  import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'

  export default {
    name: 'Pagination',
    data() {
      return {
        locale: zhCN
      }
    },
    props: {
      total: {
        required: true,
        type: Number
      },
      pageNum: {
        required: true,
        type: Number
      },
      pageSize: {
        required: true,
        type: Number
      },
    },
    computed: {
      /**
       * 页码
       */
      _pageNum: {
        get() {
          return this.pageNum
        },
        // 当一个子组件改变了一个 prop 的值时，这个变化也会同步到父组件中所绑定
        set(val) {
          this.$emit('update:pageNum', val)
        }
      },
      /**
       * 每页size
       */
      _pageSize: {
        get() {
          return this.pageSize
        },
        set(val) {
          this.$emit('update:pageSize', val)
        }
      }
    },
    methods: {
      /**
       * 页size change
       * @param pageSize
       */
      sizeChangeHandler(pageSize) {
        this.$emit('pagination', {pageNum: this.pageNum, pageSize: pageSize})
      },
      /**
       * 页码change
       * @param pageNum
       */
      pageChangeHandler(pageNum) {
        this.$emit('pagination', {pageNum: pageNum, pageSize: this.pageSize})
      },
    }

  }
</script>

<style scoped>
  .pagination-container {
    height: 30px;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-end;
  }

  .skip {
    cursor: pointer;
    float: left;
    margin: 8px 0 0 10px;
    color: #409EFF;
  }
</style>
